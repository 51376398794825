import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { DeleteItem } from "../../../utility/Form";
import { get } from "lodash";

const DeleteModal = (props) => {
  const [modalShow2, setModalShow] = useState(get(props, 'modalShow'));
  const deleteItemFunc = async () => {
    let deletedItemResult = {},
      delToaster = {};
    try {
      if (props.isStaticModal) {
        props.deleteUploadedItem({ ...props.item, index: props.itemIndex });
      } else {
        deletedItemResult = await DeleteItem(`${props.parentPath}.delete`, props.itemId, { ...props });
        delToaster = {
          showToaster: true,
          data: {
            message: 'Entry deleted successfully',
            heading: props.menu,
            variant: 'primary'
          }
        }
      }
    } catch (error) {
      console.log('DeleteModal handleAttachmentFunc error', error, props);
      delToaster = {
        showToaster: true,
        data: {
          message: get(error, 'response.data.message') || 'Unable to delete the entry!',
          heading: props.menu,
          variant: 'danger'
        }
      }
    }
    handleClose();
    if (get(props, 'getDeletedItem')) {
      props.getDeletedItem(deletedItemResult, delToaster)
    } else
      return 0;
  }

  const handleClose = () => {
    setModalShow(false);
    props.setModalShowFunc(false)
  }

  return (
    <>
      <Modal
        show={modalShow2}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="dashboard-modal"
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure you want to delete?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <p>This entry will be deleted permanently. You can't undo this action.</p>                 */}
          This entry will be deleted permanently. You can't undo this action.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="btn-primary-dashboard me-3" onClick={deleteItemFunc}>Confirm</Button>
          <Button variant="secondary" className="btn-secondary-dashboard" onClick={handleClose}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DeleteModal;
