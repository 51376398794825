import React, { useState } from "react"
import { Link } from "gatsby";
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import ModalPopUp from "../common/modal/Modal";

const TooltipOverlay = (props) => {
  const [showModal, setShowModal] = useState(false);
  const showModalFunc = () => {
    setShowModal(true);
  }

  const setModalShowFunc = () => {
    setShowModal(false);
  }
  return (
    <>
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip-2">{props.label}</Tooltip>
        }
      >
        {
          (props?.isStaticModal && props.label.toLowerCase() === "edit") ? (
            <a href="javascript:;" onClick={() => props.editUploadedItem({ ...props.item, index: props.itemIndex })}>
              <i className={"tt icon " + props.icon}></i></a>
          ) : props.label.toLowerCase() === "logout" ? (
            <a href="javascript:;" onClick={() => props.logoutFunc()}>
              <i className={"icon " + props.icon}></i></a>
          ) : (props.label.toLowerCase() === "edit" ? (
            <>
              {
                (props.link && props.link.indexOf('child-properties') > -1) ?
                  <Link to={props.link.replace('child-', '')} className={props.isEnabled ? '' : 'disabled-link'}>
                    <i className={"icon " + props.icon}></i>
                  </Link>
                  :
                  <Link to={props.link} className={props.isEnabled ? '' : 'disabled-link'}>
                    <i className={"icon " + props.icon}></i>
                  </Link>
              }
            </>
          ) : (
            <a href="javascript:;" onClick={showModalFunc} className={props.isEnabled ? '' : 'disabled-link'}><i className={"icon " + props.icon}></i></a>
          ))
        }
      </OverlayTrigger>
      {
        showModal && <ModalPopUp
          modalShow={showModal}
          //getUploadedData={props.handleClick}
          getDeletedItem={props.getDeletedItem}
          setModalShowFunc={setModalShowFunc}
          uploadedDataFieldName="images"
          {...props}
        />
      }
    </>
  )
}

export default TooltipOverlay