import React from "react";
import DeleteModal from "./Delete";
import UploadModal from "./Upload";
import UnPublishModal from "./Unpublish";
import ConfirmOnExit from "./ConfirmOnExit";

const ModalByAction = (props) => {
  switch (props.modalAction) {
    case 'upload':
      return <UploadModal {...props} />;
      break;
    case 'unpublish':
      return <UnPublishModal {...props} />;
      break;
    case 'confirm-on-exit':
      return <ConfirmOnExit {...props} />;
      break;
    default:
      return <DeleteModal {...props} />;
      break;
  }
}

const ModalPopUp = (props) => {
  return ModalByAction(props);
}

export default ModalPopUp;