import React, { useState, useEffect } from "react";
import $ from 'jquery';
import { Button } from "react-bootstrap";
import Notification from "@components/notification/Notification"
import { navigate } from "@reach/router";
import { logout } from "../../utility/Auth";
import { isEmpty, get, isArray } from "lodash";
import { Publish, UnPublish, CheckFormValidity } from "../../utility/Form";
import ModalPopUp from "./modal/Modal";
import Toaster from "../Toaster/Toaster";
import TooltipOverlay from "@components/ToottipOverlay/TooltipOverlay";

const GoToPage = (parentPath) => {
  navigate(`/${parentPath}/add`)
}

const Logout = () => {
  logout();
  navigate(`/auth/login`)
}

const TopNav = (props) => {
  const MenuClick = () => {
    $("body").toggleClass("dashboard-menu-open");
  }

  const [isPublished, setIsPublished] = useState(true);
  const [showUnPublishModel, setShowUnPublishModel] = useState(false);

  // Toaster
  const [showToaster, setShowToaster] = useState(false);
  const [toasterData, setToasterData] = useState({});
  const setShowToasterFunc = (value) => {
    setShowToaster(value);
    setToasterData({});
  }
  // Toaster

  useEffect(async () => {
    //Get item by id
    setIsPublished(!isEmpty(props.published_at));
  }, [props.published_at])

  const PublishAction = async () => {
    try {
      if (props.editItemId) {
        //Check form validity
        const { data } = await Publish(`${props.parentPath}.publish`, props.editItemId);
        setIsPublished(!isEmpty(data.published_at))
        setShowToaster(true);
        setToasterData({
          message: 'Form published successfully',
          heading: props.menu,
          variant: 'primary'
        })
        props.SetPublishActionItem(data);
      } else {
        console.log('editItemId not found');
      }
    } catch (error) {
      const ErrMsg = get(error, 'response.data.message');
      let messageNew = ErrMsg;
      if (isArray(ErrMsg)) {
        messageNew = get(error, 'response.data.message[0].messages[0].message')
      }
      setShowToaster(true);
      setToasterData({
        message: messageNew || 'Server error!',
        heading: props.menu,
        variant: 'danger'
      })
      console.log('PublishAction error', error);
    }
  }

  const UnPublishAction = async () => {
    try {
      if (props.editItemId) {
        const { data } = await UnPublish(`${props.parentPath}.unpublish`, props.editItemId);
        setIsPublished(!isEmpty(data.published_at))
        setShowToaster(true);
        setToasterData({
          message: 'Form unpublished successfully',
          heading: props.menu,
          variant: 'primary'
        })
      } else {
        console.log('editItemId not found');
      }
    } catch (error) {
      const ErrMsg = get(error, 'response.data.message');
      let messageNew = ErrMsg;
      if (isArray(ErrMsg)) {
        messageNew = get(error, 'response.data.message[0].messages[0].message')
      }
      setShowToaster(true);
      setToasterData({
        message: messageNew || 'Server error!',
        heading: props.menu,
        variant: 'danger'
      })
      console.log('UnPublishAction error', error);
    }
  }

  const ShowUnPublish = () => {
    setShowUnPublishModel(true);
  }

  const setModalShowUnPublish = (flag) => {
    setShowUnPublishModel(flag);
  }

  return (
    <>
      <header className="d-flex justify-content-between align-items-center logout-wrapper">
        <a onClick={MenuClick} className="d-lg-none d-md-inline-block"><i className="icon icon-dashboard-menu"></i></a>
        <h2 className="d-lg-block d-none">{props.menu}</h2>
        <ul className="list-inline">
          {
            (props.pageType === "List") ? (
              <li className="list-inline-item">
                <i className="bi bi-plus-square d-md-none d-inline-block icon-bootstrap-mobile"></i>
                <Button className="btn-primary-dashboard d-md-inline d-none" onClick={() => GoToPage(props.parentPath)}>{`Add ${props.addBtnText}`}</Button>
              </li>
            ) : ((props.pageType === "Edit") ? (
              (props.groupMenu !== 'settings') ?
                (
                  isPublished ? (
                    <li className="list-inline-item">
                      <i className="bi bi-plus-square d-md-none d-inline-block icon-bootstrap-mobile"></i>
                      <Button className="btn-primary-dashboard d-md-inline d-none"
                        disabled={!props?.enablePublishAction}
                        onClick={() => ShowUnPublish()}>Unpublish</Button>
                    </li>
                  ) : (
                    <li className="list-inline-item">
                      <i className="bi bi-plus-square d-md-none d-inline-block icon-bootstrap-mobile"></i>
                      <Button className="btn-primary-dashboard d-md-inline d-none"
                        disabled={!props?.enablePublishAction}
                        onClick={() => PublishAction()}>Publish</Button>
                    </li>
                  )
                ) : ""
            ) : "")
          }
          <li className="list-inline-item">
            <TooltipOverlay
              label="Logout"
              icon="icon-logout"
              logoutFunc={Logout}
              {...props}
            />
          </li>
        </ul>
      </header>
      <h2 className="d-lg-none d-block mb-3">{props.menu}</h2>
      <hr />
      {
        showUnPublishModel && <ModalPopUp
          modalShow={showUnPublishModel}
          unPublishAction={UnPublishAction}
          setModalShowUnPublish={setModalShowUnPublish}
          modalAction="unpublish"
          {...props}
        />
      }
      {
        showToaster && <Toaster
          handleClick={showToaster}
          setShowToast={(val) => setShowToasterFunc(val)}
          variant={toasterData.variant}
          heading={toasterData.heading}
          message={toasterData.message}
        />
      }
    </>
  )
}

export default TopNav;
