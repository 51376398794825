import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { get } from "lodash";

const UnPublishModal = (props) => {
  const [modalShowUnPublish, setModalShowUnPublish] = useState(get(props, 'modalShow'));
  const unpublishItemFunc = async () => {
    try {
      props.unPublishAction();
    } catch (error) {
      console.log('DeleteModal handleAttachmentFunc error', error, props);
    }
    handleClose();
  }

  const handleClose = () => {
    setModalShowUnPublish(false);
    props.setModalShowUnPublish(false)
  }

  return (
    <>
      <Modal
        show={modalShowUnPublish}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="dashboard-modal"
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure you want to unpublish?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Unpublish this content will automatically change it to a draft.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="btn-primary-dashboard me-3" onClick={unpublishItemFunc}>Unpublish</Button>
          <Button variant="secondary" className="btn-secondary-dashboard" onClick={handleClose}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default UnPublishModal;
