import React, { useState, useEffect } from "react"
import { Toast, ToastContainer } from 'react-bootstrap'
import './assets/styles/_index.scss';

const Toaster = (props) => {
  const [showToast, setShowToast] = useState(false);
  useEffect(() => {
    setShowToast(props.handleClick)
  }, [props.handleClick])

  return (
    <ToastContainer position="top-end" className="p-3 position-fixed">
      <Toast onClose={() => { setShowToast(false); props.setShowToast(false) }} show={showToast} bg={props.variant} autohide>
        <Toast.Header>
          <strong className="me-auto">{props.heading}</strong>
        </Toast.Header>
        <Toast.Body className="text-white">{props.message}</Toast.Body>
      </Toast>
    </ToastContainer>
  )
}

export default Toaster