import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { navigate } from "@reach/router";
import { get } from "lodash";

const ConfirmOnExit = (props) => {
  const [showModal, setShowModal] = useState(get(props, 'modalShow'));
  const ConfirmFunc = () => {
    HandleClose();
    navigate(`/${props.parentPath}/list`);
  }
  const HandleClose = () => {
    setShowModal(false);
    props.closeShowModalFunc();
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={HandleClose}
        backdrop="static"
        keyboard={false}
        className="dashboard-modal"
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You will not be able to recover this!
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="btn-primary-dashboard me-3" onClick={() => ConfirmFunc()}>Confirm</Button>
          <Button variant="secondary" className="btn-secondary-dashboard" onClick={() => HandleClose()}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ConfirmOnExit;