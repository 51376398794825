import React, { useState } from "react";
import { Row, Col, Button, Modal,Form } from "react-bootstrap";
import {HandleAttachmentDragDrop, GetFormValuesById} from "../../../utility/Form";
import {get, isEmpty} from "lodash";
import {useDropzone} from 'react-dropzone';

const UploadModal = (props) => {
    const [modalShow1, setModalShow] = useState(props?.modalShow);
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        accept: props.accept_format
      });
    const [disableUploadTemp, setDisableUploadTemp] = useState({
        text: 'Save',
        disable: false
    });
     const files = acceptedFiles.map(file => (
        <li key={file.path}>
          {file.path} - {file.size} bytes
        </li>
      ));

    const handleFormSubmit = async (event) => {
        try {
            if(props?.action?.isUpdate){
                const FormValues = GetFormValuesById('upload-file');
                props.getUploadedData({
                    isUpdate: props?.action?.isUpdate,
                    index: props?.action?.editItem?.index,
                    field: props?.action?.isUpdate ? props?.action?.editItem.field : props.uploadedDataFieldName,
                    ...FormValues,
                    updatedAt: new Date().toJSON()
                });
            } else {
                setDisableUploadTemp({
                    text: 'Uploading...',
                    disable: true
                });
                const formDataTemp = await HandleAttachmentDragDrop(event, {...props}, acceptedFiles);
                event.preventDefault();
                setDisableUploadTemp({
                    text: 'Save',
                    disable: false
                });
                if(get(props, 'getUploadedData') && (!isEmpty(formDataTemp)))
                    props.getUploadedData({
                        items: [...formDataTemp] || [],
                        isUpdate: props?.action?.isUpdate,
                        index: props?.action?.editItem?.index,
                        field: props?.action?.isUpdate ? props?.action?.editItem.field : props.uploadedDataFieldName
                    });
                else 
                    //If anything happend abnormally pop still on screen
                    return 1;
            }
            handleClose();
        } catch (error) {
          console.log('handleFormSubmit upload error', error);
          props.getUploadedData({}); 
        }
    }

    const handleClose = ()=>{
      setModalShow(false);
      props?.setModalShowFunc(false)
    }

    return (
        <>
            <Modal 
                show={modalShow1}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="dashboard-modal"
                centered
                size="lg"
                >
                <Form id="upload-file" noValidate>
                    <input type="hidden" name="field" value={props?.action?.isUpdate ? props?.action?.editItem.field : props.uploadedDataFieldName} />
                    <Modal.Body> 
                        {props?.action?.isUpdate && props?.action?.editItem?.url && <Row>
                            <Col lg={12}>
                                <div className="form-wrapper mb-5">
                                    <label>Existing Url</label>
                                    <div>
                                        <a target="_blank" href={props?.action?.editItem?.url}>{props?.action?.editItem?.url}</a>
                                    </div>
                                </div> 
                            </Col>
                        </Row>} 
                        {
                            !props?.action?.isUpdate && <Row className="mb-4">
                                <Col lg={12}>
                                    <h2 className="mb-3">Upload Assets</h2>
                                    <div  {...getRootProps({className: 'dropzone drag-drop-wrapper text-center mb-5'})}>
                                    <i className="icon icon-drag-drop"></i>
                                    <div className="drag-drop-heading py-3">Drag and drop files here</div>
                                    <div className="drag-drop-support pb-3">Files supported: {
                                        props?.format_text
                                    }</div>
                                    <input accept={props?.accept_format}
                                        name={props?.action?.isUpdate ? props?.action?.editItem.field : props.uploadedDataFieldName}
                                         type="file" 
                                        multiple={true}
                                        {...getInputProps()}
                                        />     
                                          <ul className="file-list">{files}</ul>                           
                                         
                                </div>  
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col lg={12}>
                                <div className="form-wrapper mb-5">
                                  <label>Category</label>
                                  <input type="text" 
                                    name={'category'} 
                                    className="form-control" 
                                    placeholder="Enter category" 
                                    defaultValue={props?.action?.editItem?.category}
                                    />                                    
                                </div>                            
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <div className="form-wrapper mb-5">
                                    <label>Caption</label>
                                    <input type="text" name="caption" 
                                      className="form-control" 
                                      placeholder="Enter caption" 
                                      defaultValue={props?.action?.editItem?.caption}
                                      />
                                </div> 
                            </Col>
                        </Row> 
                        <Row>
                            <Col lg={12}>
                                <div className="form-wrapper mb-5">
                                    <label>Order</label>
                                    <input type="number" name="order" 
                                      className="form-control" 
                                      placeholder="Enter order"
                                      min={1} 
                                      defaultValue={props?.action?.editItem?.order}
                                      />
                                </div> 
                            </Col>
                        </Row>                                                           
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="button" variant="primary" className="btn-primary-dashboard me-3"
                            disabled={disableUploadTemp.disable}
                            onClick={(event) => handleFormSubmit(event)}>
                            {disableUploadTemp.text}
                        </Button>                        
                        <Button variant="secondary" className="btn-secondary-dashboard" onClick={handleClose}>
                          Cancel
                        </Button>                    
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default UploadModal;
